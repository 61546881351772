@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;600&display=swap');
@import "./assets/scss/variables";

html {
	height: 100%;
}

body {
	box-sizing: border-box;
	height: 100%;
	margin: 0;

	font-family: 'Caveat', cursive;
	font-size: 24px;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	user-select: none;

	#root {
		height: 100%;
	}
}

h1 {
	text-align: center;
	font-size: 48px;
	font-weight: normal;
}